import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ButtonComponent,
  CustomValidators,
  InputEmailComponent,
  InputPasswordComponent,
  InputTelComponent,
  InputTextComponent,
  ToastService,
} from '@common/angular';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { MemberService } from '@api-client';
import { BaseModal } from '../base.modal';

@Component({
  selector: 'app-signup',
  imports: [
    IonContent,
    InputTextComponent,
    InputEmailComponent,
    InputPasswordComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    ModalHeaderComponent,
    InputTelComponent,
  ],
  templateUrl: './signup.modal.html',
  styleUrl: './signup.modal.scss',
})
export class SignupModal extends BaseModal {
  service = inject(MemberService);
  toast = inject(ToastService);
  modalController = inject(ModalController);

  form = new FormGroup({
    name: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    email: new FormControl('', { validators: [Validators.required, Validators.email], nonNullable: true }),
    password: new FormControl('', { validators: [Validators.required, Validators.minLength(6)], nonNullable: true }),
    passwordConfirm: new FormControl('', { validators: [Validators.required, Validators.minLength(6)], nonNullable: true }),
    tel: new FormControl('', {
      validators: [Validators.required],
      asyncValidators: [CustomValidators.memberTel(this.service)],
      nonNullable: true,
    }),
  });

  submit() {
    if (this.form.invalid) {
      return;
    }

    if (this.form.controls.password.value !== this.form.controls.passwordConfirm.value) {
      this.toast.error('비밀번호가 일치하지 않습니다.');
      return;
    }

    this.service
      .memberControllerSignUp({
        body: this.form.getRawValue(),
      })
      .subscribe({
        next: () => {
          this.toast.success('회원가입이 완료되었습니다.');
          this.modalController.dismiss();
        },
      });
  }
}
